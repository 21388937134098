import { ApplicationsFilterStatus, FilterState } from '@src/types/filter'
import { RegionRange } from '@src/types/jobPost'

export const DEFAULT_FILTER_SET: FilterState = {
  regionRange: RegionRange.RANGE_3,
  workPeriod: undefined,
  jobTasks: [],
  workDays: [],
  workTime: undefined,
  filterStatus: {
    any: false,
    region: false,
    workPeriod: false,
    jobTasks: false,
    workDays: false,
    workTime: false,
  },
}

export const initialAuthorApplicationsFilter: ApplicationsFilterStatus = {
  ageGroups: undefined,
  gender: undefined,
  statusList: undefined,
}

export const REGION_RANGE_LABEL_MAP = {
  [RegionRange.MY]: '내 동네',
  [RegionRange.ADJACENT]: '가까운 동네',
  [RegionRange.RANGE_2]: '조금 먼 동네',
  [RegionRange.RANGE_3]: '먼 동네',
  [RegionRange.RANGE_4]: '더 먼 동네',
}
