import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './shim'

import { IS_ALPHA } from '@src/config'
import { createEnvironment } from '@src/graphql/graphql'
import { captureException } from '@src/sdks/sentry'

import { preload } from './preload'

async function main() {
  const relayEnvironment = createEnvironment()

  if (IS_ALPHA) {
    const { initVConsole } = await import('./sdks/vConsole/vConsole')
    initVConsole({ relayEnvironment })
  }

  try {
    preload({
      path: window.location.pathname,
      search: window.location.search,
      relayEnvironment,
    })

    const { render } = await import('./render')
    render({
      relayEnvironment,
    })
  } catch (err) {
    console.error(err)
    captureException(err)
  }
}

main()
