import { graphql } from 'relay-runtime'

import {
  ExperimentSegmentGroup,
  JobsInitialDataFetcherInitExperimentMutation$variables,
} from '@src/__generated__/JobsInitialDataFetcherInitExperimentMutation.graphql'
import { parseSegment, treatmentSymbolToServerGroup } from '@src/utils/experiment'

export const JobsInitialDataFetcherQueryNode = graphql`
  query JobsInitialDataFetcherQuery($eventGroupsWhere: EventGroupsWhereInput!, $regionExist: Boolean!) {
    me {
      id
      role
      resume {
        birthYear
      }
      jobPosts(first: 2, filter: { status: [ACCEPTED, PENDING] }) @connection(key: "MainWritingsList_jobPosts") {
        edges {
          node {
            id
            advertisements {
              __typename
              status
            }
          }
        }
      }
      author {
        experiencedBusinessTypes
      }
      jobPostsExtend {
        availableSlotCount
      }
      resettableDeviceId
      initialReferrer
    }
    eventGroups(where: $eventGroupsWhere) @include(if: $regionExist) {
      type
      name
      hasJobPost
      view {
        search {
          suggest {
            __typename
          }
        }
      }
    }
  }
`

export const JobsInitialDataFetcherInitExperimentMutationNode = graphql`
  mutation JobsInitialDataFetcherInitExperimentMutation(
    $registerData: [RegisterExperimentSegmentDataInput!]!
    $endData: [EndExperimentSegmentDataInput!]!
  ) {
    registerExperimentSegments(data: $registerData) {
      experimentSegments {
        name
        version
        group
        endAt
        endReason
      }
    }
    endExperimentSegment(data: $endData) {
      success
    }
  }
`

export const JobsInitialDataFetcherInitExperimentMutationVariables = ({
  enableSegments,
  deletedSegments,
}: {
  enableSegments: string[]
  deletedSegments: string[]
}): JobsInitialDataFetcherInitExperimentMutation$variables => {
  return {
    registerData: enableSegments.map((rawSegment) => {
      const segment = parseSegment(rawSegment)
      const group = segment.isExclude
        ? 'EXCLUDE'
        : segment.isControl
          ? 'CONTROL'
          : treatmentSymbolToServerGroup(segment.treatmentType)

      return {
        name: segment.name,
        version: segment.version,
        group: group as ExperimentSegmentGroup,
      }
    }),
    endData: deletedSegments.map((rawSegment) => {
      const segment = parseSegment(rawSegment)
      return {
        name: segment.name,
        version: segment.version,
        endReason: 'OUTDATED_ACCESS',
      }
    }),
  }
}
