import CryptoJS from 'crypto-js'
import { startOfDay } from 'date-fns'
import { compact } from 'lodash'

import { CURRENT_MIN_SALARY, WEEKS_PER_MONTH } from '@src/constants/business'
import { allJobTasksMap, consideredQualificationsMap, welfareMap } from '@src/constants/form'
import { WALKING_DISTANCE_MAX_METER, WALKING_DISTANCE_METER_PER_MINUTES } from '@src/constants/walkingDistance'
import {
  AllJobTaskType,
  PersonalJobGuide,
  PersonalJobTaskType,
  SalaryType,
  WorkDay,
  WorkPeriod,
} from '@src/types/jobPost'
import { ConsideredQualification, Welfare } from '@src/types/jobPostExtend'

import { calcPassedDate, isPastDateAddDaysLimit, MS } from './date'
import { stringifyWorkDates } from './form'
import { maskPrice, priceFormat } from './number'
import { cleanWorkDayString, formatTimeTo12Hour, mapSalaryType, mapWorkDay, sortWorkDays } from './string'

export const calcShortWorkPeriod = (workPeriod: WorkPeriod | undefined | null): boolean => {
  return workPeriod === 'LESS_THAN_A_MONTH'
}

export const calcJobPostExpired = (jobPost: { closed?: boolean }) => {
  return !!jobPost.closed
}

export const stringifyWorkDays = (workDays: WorkDay[]) => cleanWorkDayString(sortWorkDays(workDays).map(mapWorkDay))

export const stringifyWorkDatesAndDays = ({
  workDates = [],
  workDays = [],
  isPastWorkDatesVisible = true,
}: {
  workDates?: readonly unknown[] | null
  workDays?: readonly unknown[] | null
  isPastWorkDatesVisible?: boolean
}) => {
  if (!workDates || !workDates.length) {
    return `${stringifyWorkDays((workDays || []) as Parameters<typeof stringifyWorkDays>[0])}`
  }

  const handledWorkDates = isPastWorkDatesVisible
    ? workDates
    : workDates.filter((date) => new Date(date as Dateable).getTime() >= startOfDay(new Date()).getTime())

  //TODO: 서버에서 단기 알바공고 자동마감 처리되면 과거 근무일정(handledWorkDates.length=0) 대응 코드 제거
  return stringifyWorkDates(
    (handledWorkDates.length ? handledWorkDates : workDates) as Parameters<typeof stringifyWorkDates>[0]
  )
}

export function calcWorkTimeDifference(workTimeStart: string, workTimeEnd: string) {
  const [startHour, startMinute] = workTimeStart.split(':').map(Number)
  const [endHour, endMinute] = workTimeEnd.split(':').map(Number)

  const start = startHour + startMinute / 60
  const end = endHour + endMinute / 60

  const hourDiff = end - start
  return hourDiff < 0 ? hourDiff + 24 : hourDiff
}

export function calcActualWorkTime(dailyWorkHours: number) {
  if (dailyWorkHours < 4) {
    return dailyWorkHours
  }

  if (dailyWorkHours < 8) {
    return dailyWorkHours - 0.5
  }

  return dailyWorkHours - 1
}

export function calcMinMonthlySalary({
  workHours,
  workDayCountPerWeek,
}: {
  workHours: number
  workDayCountPerWeek: number
}) {
  const baseWeekyWorkHours = workHours * workDayCountPerWeek

  // 2025년 최저 임금 고시에 따라 소정 근로시간이 40시간인 경우 한정으로 결과(40*1.2*4.345=208.56)에 대해 반올림을 적용하여 209로 계산
  // https://daangn.slack.com/archives/C07TTEMNFA9/p1741598923557829?thread_ts=1741249459.822359&cid=C07TTEMNFA9
  if (baseWeekyWorkHours === 40) {
    return 209 * CURRENT_MIN_SALARY
  }

  const weeklyWorkHours =
    baseWeekyWorkHours < 15
      ? baseWeekyWorkHours
      : baseWeekyWorkHours < 40
        ? // 40시간 이하는 주휴수당(소정근로시간)을 일한 시간에 대해 5로 나누어 비례해서 지급(현재 시간의 1.2배)하며, 최대 8시간까지 적용
          // https://daangn.slack.com/archives/CUQNTPR53/p1738731082077489?thread_ts=1738720188.141329&cid=CUQNTPR53
          baseWeekyWorkHours * 1.2
        : baseWeekyWorkHours + 8

  return Math.ceil(weeklyWorkHours * WEEKS_PER_MONTH * CURRENT_MIN_SALARY)
}

export function calcMinDailySalary(workHours: number) {
  return workHours * CURRENT_MIN_SALARY
}

export const stringifyWorkTime = ({
  workTimeStart = '00:00',
  workTimeEnd = '00:00',
  isWorkTimeNegotiable,
  isPersonal,
  format = '24H',
}: {
  workTimeStart?: string | null
  workTimeEnd?: string | null
  isWorkTimeNegotiable?: boolean | null
  isPersonal?: boolean | null
  format?: '12H' | '24H'
}) => {
  const formatTime = (time: string) => (format === '12H' ? formatTimeTo12Hour(time) : time)

  return isPersonal && isWorkTimeNegotiable
    ? '시간 협의'
    : `${formatTime(workTimeStart ?? '00:00')} ~ ${formatTime(workTimeEnd ?? '00:00')}${
        isWorkTimeNegotiable ? ' 협의' : ''
      }`
}

export const stringifyBringUpPassedDate = (jobPost: {
  lastBringUpDate?: Dateable | null
  publishedAt?: Dateable | null
}) => {
  if (jobPost.lastBringUpDate) {
    return `끌올 ${calcPassedDate(jobPost.lastBringUpDate)} 전`
  }

  return `${calcPassedDate(jobPost.publishedAt ?? new Date())} 전`
}

export const stringifySalary = ({
  salaryType,
  salary,
  showType = true,
}: {
  salaryType?: SalaryType | '%future added value' | null
  salary?: number | null
  showType?: boolean
}) => {
  const typeText = mapSalaryType(salaryType)
  const salaryText = (salary ?? 0) < 1000000 ? `${priceFormat(salary)}원` : maskPrice(salary)
  return `${showType ? `${typeText} ` : ''}${salaryText}`
}

export const stringifyWelfare = (welfare?: Welfare[] | null) => {
  return welfare
    ?.map((w) => welfareMap[w])
    .filter((w) => w)
    .join(', ')
}

export const stringifyConsideredQualifications = (consideredQualifications?: ConsideredQualification[] | null) => {
  return consideredQualifications
    ?.map((q) => consideredQualificationsMap[q])
    .filter((q) => q)
    .join(', ')
}

type Pos = { lat: number; lng: number } | { latitude: number; longitude: number } | [number, number]

export const checkWalkingDistance = (meters?: number | null) => {
  if (!meters) return false
  return meters <= WALKING_DISTANCE_MAX_METER
}

export const calcWalkingDistance = ({ positions }: { positions: (Pos | undefined | null)[] }) => {
  const meter = compact(positions).reduce((acc, cur, idx, arr) => {
    if (idx === 0 || !arr[0] || !arr[1]) return 0

    const prev = arr[idx - 1]
    const pos1 = Array.isArray(prev)
      ? { lat: prev[0], lng: prev[1] }
      : 'latitude' in prev
        ? { lat: prev.latitude, lng: prev.longitude }
        : prev
    const pos2 = Array.isArray(cur)
      ? { lat: cur[0], lng: cur[1] }
      : 'latitude' in cur
        ? { lat: cur.latitude, lng: cur.longitude }
        : cur

    if (!pos1.lat || !pos1.lng || !pos2.lat || !pos2.lng) return 0

    return acc + Math.floor(calcHaversineMeterDistance({ pos1, pos2 }))
  }, 0)

  if (!meter) {
    return {
      meter: null,
      minutes: null,
      isWalkingDistance: false,
    }
  }

  return {
    meter,
    minutes: Math.ceil(meter / WALKING_DISTANCE_METER_PER_MINUTES),
    isWalkingDistance: checkWalkingDistance(meter),
  }
}

export const calcHaversineMeterDistance = ({
  pos1,
  pos2,
}: {
  pos1: { lat: number; lng: number }
  pos2: { lat: number; lng: number }
}) => {
  const R = 6371
  const toRadian = Math.PI / 180
  const dLat = Math.abs(pos2.lat - pos1.lat) * toRadian
  const dLng = Math.abs(pos2.lng - pos1.lng) * toRadian
  const sinDeltaLat = Math.sin(dLat / 2)
  const sinDeltaLng = Math.sin(dLng / 2)
  const squareRoot = Math.sqrt(
    sinDeltaLat * sinDeltaLat +
      Math.cos(pos2.lat * toRadian) * Math.cos(pos1.lat * toRadian) * sinDeltaLng * sinDeltaLng
  )

  const kilometerDistance = 2 * R * Math.asin(squareRoot)
  return kilometerDistance * 1000
}

export const calcProtectedOffsetLocation = ({
  lat,
  lng,
  meterOffset,
}: {
  lat: number
  lng: number
  meterOffset: number
}) => {
  const hashedString = hashCoordinates(lat, lng)
  const numericHash = generateNumericHash(hashedString)
  const degreeOffset = calculateDegreeOffset(meterOffset)
  const [degreeOffsetFactorLat, degreeOffsetFactorLng] = generateDegreeOffsetFactors(numericHash)

  return {
    lat: lat + degreeOffset * degreeOffsetFactorLat,
    lng: lng + degreeOffset * degreeOffsetFactorLng,
  }
}

const hashCoordinates = (lat: number, lng: number) => CryptoJS.SHA256(`${lat}${lng}`).toString()

const generateNumericHash = (hashedString: string) => {
  let numericHash = 0
  for (let i = 0; i < hashedString.length; i++) {
    const charCode = hashedString.charCodeAt(i)
    numericHash = (numericHash << 5) - numericHash + charCode
    numericHash |= 0
  }
  return Math.abs(numericHash)
}

const calculateDegreeOffset = (offsetMeters: number) => {
  const DEGREES_PER_METER = 0.00001
  return DEGREES_PER_METER * Math.sqrt(Math.pow(offsetMeters, 2) / 2)
}

const generateDegreeOffsetFactors = (numericHash: number) => {
  const degreeOffsetFactorLat = normalizeFactor(Math.floor((numericHash % 10000) / 100))
  const degreeOffsetFactorLng = normalizeFactor(numericHash % 100)
  return [degreeOffsetFactorLat, degreeOffsetFactorLng]
}

const normalizeFactor = (value: number) => ((value - 50) * 2) / 100

export const calcReopenableDate = (publishedAt?: Dateable | null) => {
  if (!publishedAt) return false
  return !isPastDateAddDaysLimit({ targetDate: publishedAt, days: 30 })
}

export const isAllJobTaskType = (jobTask: AllJobTaskType | '%future added value'): jobTask is AllJobTaskType => {
  return jobTask in allJobTasksMap
}

export const mapAllJobTask = (jobTask: AllJobTaskType | '%future added value') => {
  return isAllJobTaskType(jobTask) ? allJobTasksMap[jobTask] : undefined
}

// 이웃알바 workDates로 부터 AS_SOON_AS_POSSIBLE 인지 체크, AS_SOON_AS_POSSIBLE: workDates length가 7개 이고, 각각의 value가 이전 index와 1일씩 차이나는 경우로 정의함
export const checkAsSoonAsPossibleWorkPeriod = (workDates: readonly Dateable[]) => {
  const dates = workDates.map((date) => startOfDay(new Date(date)))

  return (
    dates.length === 7 &&
    dates.every((date, index) => {
      if (index === 0) return true
      const prevDate = dates[index - 1]
      const diffTime = date.getTime() - prevDate.getTime()
      const diffDays = diffTime / MS.day
      return diffDays === 1
    })
  )
}

/**
 * PersonalJobTaskType을 해당하는 PersonalJobGuide로 매핑해요.
 *
 * @param task - 매핑할 개인 알바 업무 타입
 * @returns 매핑된 PersonalJobGuide 또는 매핑되지 않는 경우 null
 *
 * @example
 * ```ts
 * getPersonalJobTaskGuide('KIDS_PICK_UP') // PersonalJobGuide.PICK_UP
 * getPersonalJobTaskGuide('PET_CARE') // PersonalJobGuide.PET_CARE
 * ```
 */
export const getPersonalJobGuideType = (task: PersonalJobTaskType): PersonalJobGuide | null => {
  switch (task) {
    case 'KIDS_PICK_UP':
    case 'CHILD_CARE':
      return PersonalJobGuide.PICK_UP

    case 'PET_CARE':
      return PersonalJobGuide.PET_CARE

    case 'HOUSEWORK':
    case 'MOVING_ASSISTANCE':
      return PersonalJobGuide.MOVING_ASSISTANCE_AND_HOUSEWORK

    default:
      return null
  }
}
