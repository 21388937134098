/**
 * @generated SignedSource<<955971bb6903e3e4594568929049098b>>
 * @relayHash d3cd730c4a36b5c07af02d241f9e97f5
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d3cd730c4a36b5c07af02d241f9e97f5

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPostStatus = "ACCEPTED" | "CLOSED" | "CREATED" | "DELETED" | "PENDING" | "REJECTED" | "RETURNED" | "%future added value";
export type RegionRange = "ADJACENT" | "MY" | "NO_PUBLISH" | "RANGE_2" | "RANGE_3" | "RANGE_4" | "%future added value";
export type FeedWhereInput = {
  region: number;
  regionRange?: RegionRange;
};
export type UserJobPostsFilterInput = {
  status?: ReadonlyArray<JobPostStatus> | null | undefined;
};
export type MainPageContentsQuery$variables = {
  isFirstHiredExperienceBannerClicked: boolean;
  jobPostsFilter?: UserJobPostsFilterInput | null | undefined;
  topPremiumWhere: FeedWhereInput;
};
export type MainPageContentsQuery$data = {
  readonly me: {
    readonly region: {
      readonly name3: string | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"NewActivityBadgeAcquiredCallout_user">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"MainPageContentsBannerSection_query" | "MainTopPremiumAdJobPosts_query" | "MainWritings_query" | "RestrictionInlineAlert_query">;
};
export type MainPageContentsQuery = {
  response: MainPageContentsQuery$data;
  variables: MainPageContentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isFirstHiredExperienceBannerClicked"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jobPostsFilter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "topPremiumWhere"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name3",
  "storageKey": null
},
v4 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "topPremiumWhere"
},
v5 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "jobPostsFilter"
},
v6 = {
  "kind": "Literal",
  "name": "filter",
  "value": {
    "reasonTypes": [
      "DID_NOT_KEEP_PROMISE"
    ]
  }
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  (v3/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "kind": "Literal",
  "name": "first",
  "value": 2
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v14 = {
  "name": "stringifiedSalary",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salary",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salaryType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "JobPostExperiment",
        "kind": "LinkedField",
        "name": "experiment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "salaryTextOverride",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "JobPost",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "salaryPayMethod",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "images",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Region",
  "kind": "LinkedField",
  "name": "workplaceRegion",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name2",
      "storageKey": null
    },
    (v3/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Point",
  "kind": "LinkedField",
  "name": "workplaceLocation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lng",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v20 = {
  "name": "hasFastResponseBadge",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthorBadge",
        "kind": "LinkedField",
        "name": "badge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              (v19/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  }
                ],
                "type": "FastResponseBadge",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "HoianUserAuthor",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v21 = [
  (v19/*: any*/)
],
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "Author",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Award",
      "kind": "LinkedField",
      "name": "perfectAuthor",
      "plural": false,
      "selections": (v21/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "hoianUser",
  "plural": false,
  "selections": [
    {
      "name": "receivedApplicantReviewCount",
      "args": null,
      "fragment": {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": "receivedJobReviewsCountByApplicant",
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "evaluator": "APPLICANT",
                  "rating": [
                    "GREAT",
                    "GOOD",
                    "BAD"
                  ]
                }
              }
            ],
            "kind": "ScalarField",
            "name": "receivedJobReviewsCount",
            "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"rating\":[\"GREAT\",\"GOOD\",\"BAD\"]})"
          }
        ],
        "type": "User",
        "abstractKey": null
      },
      "kind": "RelayResolver",
      "storageKey": null,
      "isOutputType": true
    },
    (v22/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v24 = {
  "kind": "InlineFragment",
  "selections": [
    (v7/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exposureType",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acquiredAt",
  "storageKey": null
},
v28 = [
  {
    "kind": "Literal",
    "name": "kind",
    "value": "GYEONGGI_LIGHT_JOB"
  }
],
v29 = [
  (v25/*: any*/)
],
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "businessType",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bringUpCount",
  "storageKey": null
},
v32 = [
  (v5/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCash",
  "storageKey": null
},
v34 = [
  (v33/*: any*/),
  (v7/*: any*/)
],
v35 = {
  "alias": null,
  "args": null,
  "concreteType": "BizUser",
  "kind": "LinkedField",
  "name": "bizUser",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Advertiser",
      "kind": "LinkedField",
      "name": "latestAdvertiser",
      "plural": false,
      "selections": (v34/*: any*/),
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetType",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v38 = [
  (v19/*: any*/),
  (v7/*: any*/)
],
v39 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "images",
  "plural": true,
  "selections": (v38/*: any*/),
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentSlotCount",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slotCount",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayState",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nextAvailableBringUpDate",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v19/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "hoianUser",
          "plural": false,
          "selections": [
            (v35/*: any*/),
            (v7/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "HoianUserAuthor",
      "abstractKey": null
    },
    (v24/*: any*/)
  ],
  "storageKey": null
},
v45 = {
  "alias": "freeExposureStartAt",
  "args": null,
  "kind": "ScalarField",
  "name": "exposureStartAt",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "benefitReason",
  "storageKey": null
},
v47 = {
  "alias": "paidExposureStartAt",
  "args": null,
  "kind": "ScalarField",
  "name": "exposureStartAt",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refundReason",
  "storageKey": null
},
v50 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v51 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "canceled": false,
      "expired": false,
      "statusList": [
        "WAITING",
        "IN_PROGRESS",
        "HIRED"
      ]
    }
  },
  (v50/*: any*/)
],
v52 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MainPageContentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "region",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewActivityBadgeAcquiredCallout_user"
          }
        ],
        "storageKey": null
      },
      {
        "args": [
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "MainTopPremiumAdJobPosts_query"
      },
      {
        "args": [
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "MainWritings_query"
      },
      {
        "args": [
          (v6/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "RestrictionInlineAlert_query"
      },
      {
        "args": [
          {
            "kind": "Variable",
            "name": "isFirstHiredExperienceBannerClicked",
            "variableName": "isFirstHiredExperienceBannerClicked"
          }
        ],
        "kind": "FragmentSpread",
        "name": "MainPageContentsBannerSection_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MainPageContentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "region",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nickname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Resume",
            "kind": "LinkedField",
            "name": "resume",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "status": [
                    "PENDING",
                    "ACCEPTED"
                  ]
                }
              },
              (v10/*: any*/)
            ],
            "concreteType": "JobPostConnection",
            "kind": "LinkedField",
            "name": "jobPosts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPostEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v20/*: any*/),
                              (v23/*: any*/)
                            ],
                            "type": "HoianUserAuthor",
                            "abstractKey": null
                          },
                          (v24/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPostAdvertisement",
                        "kind": "LinkedField",
                        "name": "advertisements",
                        "plural": true,
                        "selections": [
                          (v25/*: any*/),
                          (v26/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "jobPosts(filter:{\"status\":[\"PENDING\",\"ACCEPTED\"]},first:2)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Author",
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPost",
                "kind": "LinkedField",
                "name": "hireableJobPost",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v6/*: any*/),
              {
                "kind": "Literal",
                "name": "limit",
                "value": 1
              }
            ],
            "concreteType": "UserActionRestriction",
            "kind": "LinkedField",
            "name": "restrictedActions",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDate",
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": "restrictedActions(filter:{\"reasonTypes\":[\"DID_NOT_KEEP_PROMISE\"]},limit:1)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Applicant",
            "kind": "LinkedField",
            "name": "applicant",
            "plural": false,
            "selections": [
              {
                "condition": "isFirstHiredExperienceBannerClicked",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "filter",
                        "value": {
                          "type": [
                            "DAANGN",
                            "GWANGJU_GWANGSAN"
                          ]
                        }
                      },
                      (v10/*: any*/)
                    ],
                    "concreteType": "ResumeExperienceConnection",
                    "kind": "LinkedField",
                    "name": "experiences",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResumeExperience",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "experiences(filter:{\"type\":[\"DAANGN\",\"GWANGJU_GWANGSAN\"]},first:2)"
                  }
                ]
              },
              {
                "name": "acquiredApplicantBadges",
                "args": null,
                "fragment": {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "badges",
                      "plural": true,
                      "selections": [
                        (v19/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v7/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "kind",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "jobTasks",
                              "storageKey": null
                            },
                            (v27/*: any*/)
                          ],
                          "type": "ApplicantBadgeMaster",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v7/*: any*/),
                            (v27/*: any*/)
                          ],
                          "type": "ApplicantBadgePro",
                          "abstractKey": null
                        },
                        (v24/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Applicant",
                  "abstractKey": null
                },
                "kind": "RelayResolver",
                "storageKey": null,
                "isOutputType": true
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TermsOfService",
            "kind": "LinkedField",
            "name": "termsOfService",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "applicantTermsAgreed",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v28/*: any*/),
            "concreteType": "MyCampaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              {
                "name": "isRegistered",
                "args": null,
                "fragment": {
                  "kind": "InlineFragment",
                  "selections": (v29/*: any*/),
                  "type": "MyCampaign",
                  "abstractKey": null
                },
                "kind": "RelayResolver",
                "storageKey": null,
                "isOutputType": true
              }
            ],
            "storageKey": "campaign(kind:\"GYEONGGI_LIGHT_JOB\")"
          },
          {
            "name": "acquiredActivityBadgeCount",
            "args": null,
            "fragment": {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserActivityBadge",
                  "kind": "LinkedField",
                  "name": "activityBadge",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "items",
                      "plural": true,
                      "selections": [
                        (v19/*: any*/),
                        (v9/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "count",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "User",
              "abstractKey": null
            },
            "kind": "RelayResolver",
            "storageKey": null,
            "isOutputType": true
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "page",
            "value": 1
          },
          {
            "kind": "Literal",
            "name": "size",
            "value": 4
          },
          (v4/*: any*/)
        ],
        "concreteType": "FeedCard",
        "kind": "LinkedField",
        "name": "topFeed",
        "plural": true,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedAdvertisementInfo",
            "kind": "LinkedField",
            "name": "advertisement",
            "plural": false,
            "selections": [
              (v26/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertisementCallback",
                "kind": "LinkedField",
                "name": "callback",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "click",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "impression",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hide",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobPost",
            "kind": "LinkedField",
            "name": "jobPost",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "name": "isPerfectAuthor",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "User",
                              "kind": "LinkedField",
                              "name": "hoianUser",
                              "plural": false,
                              "selections": [
                                (v22/*: any*/),
                                (v7/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "HoianUserAuthor",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuthorBadge",
                        "kind": "LinkedField",
                        "name": "badge",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": (v21/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v20/*: any*/),
                      (v23/*: any*/)
                    ],
                    "type": "HoianUserAuthor",
                    "abstractKey": null
                  },
                  (v24/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "author",
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v32/*: any*/),
            "concreteType": "JobPostConnection",
            "kind": "LinkedField",
            "name": "jobPosts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPostEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "closed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deleted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastBringUpDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "publishedAt",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewCount",
                        "storageKey": null
                      },
                      (v25/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Region",
                        "kind": "LinkedField",
                        "name": "workplaceRegion",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          {
                            "name": "isFranchiseEnterprise",
                            "args": null,
                            "fragment": {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": [
                                    {
                                      "kind": "Literal",
                                      "name": "kind",
                                      "value": "HOSIGI_CHICKEN"
                                    }
                                  ],
                                  "concreteType": "MyCampaign",
                                  "kind": "LinkedField",
                                  "name": "campaign",
                                  "plural": false,
                                  "selections": [
                                    (v25/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Campaign",
                                      "kind": "LinkedField",
                                      "name": "campaign",
                                      "plural": false,
                                      "selections": (v29/*: any*/),
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": "campaign(kind:\"HOSIGI_CHICKEN\")"
                                }
                              ],
                              "type": "User",
                              "abstractKey": null
                            },
                            "kind": "RelayResolver",
                            "storageKey": null,
                            "isOutputType": true
                          },
                          (v7/*: any*/),
                          (v35/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayUnavailable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPostAdvertisement",
                        "kind": "LinkedField",
                        "name": "advertisements",
                        "plural": true,
                        "selections": [
                          (v19/*: any*/),
                          (v25/*: any*/),
                          (v36/*: any*/),
                          (v37/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BusinessLicense",
                        "kind": "LinkedField",
                        "name": "businessLicense",
                        "plural": false,
                        "selections": [
                          (v39/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "hoianUser",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BizUser",
                                    "kind": "LinkedField",
                                    "name": "bizUser",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Advertiser",
                                        "kind": "LinkedField",
                                        "name": "latestAdvertiser",
                                        "plural": false,
                                        "selections": [
                                          (v11/*: any*/),
                                          (v7/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v7/*: any*/),
                                  {
                                    "name": "isPotentialCloseJobPostAbuser",
                                    "args": null,
                                    "fragment": {
                                      "kind": "InlineFragment",
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "JobPostExtend",
                                          "kind": "LinkedField",
                                          "name": "jobPostsExtend",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "closedJobPostCount",
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "type": "User",
                                      "abstractKey": null
                                    },
                                    "kind": "RelayResolver",
                                    "storageKey": null,
                                    "isOutputType": true
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "JobPostExtend",
                                    "kind": "LinkedField",
                                    "name": "jobPostsExtend",
                                    "plural": false,
                                    "selections": [
                                      (v40/*: any*/),
                                      (v41/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "availableSlotCount",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "HoianUserAuthor",
                            "abstractKey": null
                          },
                          (v24/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rejectedBecauseOf",
                        "storageKey": null
                      },
                      (v42/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "welfare",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "consideredQualifications",
                        "storageKey": null
                      },
                      (v43/*: any*/),
                      (v31/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "advertisementAvailable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "exposureItems",
                        "plural": true,
                        "selections": (v21/*: any*/),
                        "storageKey": null
                      },
                      (v19/*: any*/),
                      {
                        "name": "currentExposureItem",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            (v7/*: any*/),
                            (v25/*: any*/),
                            (v44/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "exposureItems",
                              "plural": true,
                              "selections": [
                                (v19/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v45/*: any*/),
                                    {
                                      "alias": "freeExposureEndAt",
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "exposureEndAt",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": "freeExposureHours",
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "exposureHours",
                                      "storageKey": null
                                    },
                                    (v46/*: any*/)
                                  ],
                                  "type": "JobPostFreeExposureItem",
                                  "abstractKey": null
                                },
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v47/*: any*/),
                                    {
                                      "alias": "paidExposureEndAt",
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "exposureEndAt",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": "paidExposureHours",
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "exposureHours",
                                      "storageKey": null
                                    },
                                    (v48/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "paymentDueAt",
                                      "storageKey": null
                                    },
                                    (v49/*: any*/),
                                    (v46/*: any*/)
                                  ],
                                  "type": "JobPostPaidExposureItem",
                                  "abstractKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "JobPost",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      },
                      {
                        "name": "currentAdvertisement",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            (v7/*: any*/),
                            (v25/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "JobPostAdvertisement",
                              "kind": "LinkedField",
                              "name": "advertisements",
                              "plural": true,
                              "selections": [
                                (v36/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "budget",
                                  "storageKey": null
                                },
                                (v37/*: any*/),
                                (v25/*: any*/),
                                (v26/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "materialId",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "unitId",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Advertiser",
                                  "kind": "LinkedField",
                                  "name": "advertiser",
                                  "plural": false,
                                  "selections": [
                                    (v11/*: any*/),
                                    (v33/*: any*/),
                                    (v7/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                (v7/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "JobPost",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      },
                      {
                        "name": "displayStatus",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            (v25/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "hidden",
                              "storageKey": null
                            },
                            (v42/*: any*/),
                            (v30/*: any*/),
                            (v44/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Identification",
                              "kind": "LinkedField",
                              "name": "identification",
                              "plural": false,
                              "selections": (v21/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": (v51/*: any*/),
                              "concreteType": "ApplicationConnection",
                              "kind": "LinkedField",
                              "name": "applications",
                              "plural": false,
                              "selections": (v52/*: any*/),
                              "storageKey": "applications(filter:{\"canceled\":false,\"expired\":false,\"statusList\":[\"WAITING\",\"IN_PROGRESS\",\"HIRED\"]},first:1)"
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "BusinessLicense",
                              "kind": "LinkedField",
                              "name": "businessLicense",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "validatedAt",
                                  "storageKey": null
                                },
                                (v39/*: any*/),
                                (v7/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "exposureItems",
                              "plural": true,
                              "selections": [
                                (v19/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v45/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "disableReason",
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "JobPostFreeExposureItem",
                                  "abstractKey": null
                                },
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v49/*: any*/),
                                    (v48/*: any*/),
                                    (v47/*: any*/)
                                  ],
                                  "type": "JobPostPaidExposureItem",
                                  "abstractKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "JobPostAdvertisement",
                              "kind": "LinkedField",
                              "name": "advertisements",
                              "plural": true,
                              "selections": [
                                (v25/*: any*/),
                                (v37/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Advertiser",
                                  "kind": "LinkedField",
                                  "name": "advertiser",
                                  "plural": false,
                                  "selections": (v34/*: any*/),
                                  "storageKey": null
                                },
                                (v7/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "name": "isPersonal",
                              "args": null,
                              "fragment": {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v12/*: any*/)
                                ],
                                "type": "JobPost",
                                "abstractKey": null
                              },
                              "kind": "RelayResolver",
                              "storageKey": null,
                              "isOutputType": true
                            }
                          ],
                          "type": "JobPost",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      },
                      {
                        "name": "liveApplicationsCount",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": "totalApplications",
                              "args": (v51/*: any*/),
                              "concreteType": "ApplicationConnection",
                              "kind": "LinkedField",
                              "name": "applications",
                              "plural": false,
                              "selections": (v52/*: any*/),
                              "storageKey": "applications(filter:{\"canceled\":false,\"expired\":false,\"statusList\":[\"WAITING\",\"IN_PROGRESS\",\"HIRED\"]},first:1)"
                            }
                          ],
                          "type": "JobPost",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      },
                      {
                        "name": "nextAvailableBringUpTimes",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            (v31/*: any*/),
                            (v43/*: any*/)
                          ],
                          "type": "JobPost",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v32/*: any*/),
            "filters": [
              "filter"
            ],
            "handle": "connection",
            "key": "MainWritingsList_jobPosts",
            "kind": "LinkedHandle",
            "name": "jobPosts"
          },
          {
            "alias": "log_is_biz",
            "args": [
              (v50/*: any*/)
            ],
            "concreteType": "JobPostConnection",
            "kind": "LinkedField",
            "name": "jobPosts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPostEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v38/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "jobPosts(first:1)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobPostExtend",
            "kind": "LinkedField",
            "name": "jobPostsExtend",
            "plural": false,
            "selections": [
              (v40/*: any*/),
              (v41/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v28/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaignByKind",
        "plural": false,
        "selections": [
          {
            "name": "isActive",
            "args": null,
            "fragment": {
              "kind": "InlineFragment",
              "selections": (v29/*: any*/),
              "type": "Campaign",
              "abstractKey": null
            },
            "kind": "RelayResolver",
            "storageKey": null,
            "isOutputType": true
          },
          (v25/*: any*/)
        ],
        "storageKey": "campaignByKind(kind:\"GYEONGGI_LIGHT_JOB\")"
      }
    ]
  },
  "params": {
    "id": "d3cd730c4a36b5c07af02d241f9e97f5",
    "metadata": {},
    "name": "MainPageContentsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "440d61a01201e05f2e8e4c4e3f6e9855";

export default node;
