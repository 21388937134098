import { graphql } from 'react-relay'
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments'

import { UserReceivedApplicantReviewCountResolver$key } from '@src/__generated__/UserReceivedApplicantReviewCountResolver.graphql'

/**
 *
 * @RelayResolver User.receivedApplicantReviewCount: Int
 * @rootFragment UserReceivedApplicantReviewCountResolver
 *
 * 구직자가 구인자에게 남긴 GREAT, GOOD, BAD 후기
 */
export function receivedApplicantReviewCount(userRef: UserReceivedApplicantReviewCountResolver$key): number {
  const user = readFragment(
    graphql`
      fragment UserReceivedApplicantReviewCountResolver on User {
        receivedJobReviewsCountByApplicant: receivedJobReviewsCount(
          filter: { evaluator: APPLICANT, rating: [GREAT, GOOD, BAD] }
        )
      }
    `,
    userRef
  )

  return user.receivedJobReviewsCountByApplicant ?? 0
}
