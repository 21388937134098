import { relayPluginModel } from '@src/sdks/vConsole/plugins/relay.model'
import { Middleware } from '@src/types/relay'

export const vConsoleMiddleware = (): Middleware => {
  return (next) => (req) => {
    const startTime = Date.now()
    return next(req).then((res) => {
      const endTime = Date.now()

      relayPluginModel.addRequest({
        query: req.id,
        text: req.operation.text,
        variables: req.variables,
        response: (res as any).data,
        duration: endTime - startTime,
        timestamp: new Date().toISOString(),
      })

      return res
    })
  }
}
