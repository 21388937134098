/**
 * 도보 10분 거리에 해당하는 미터 단위 거리
 * @constant
 * @type {number}
 */
export const WALKING_DISTANCE_MAX_METER = 600

/**
 * 도보 1분 거리에 해당하는 미터 단위 거리
 * @constant
 * @type {number}
 */
export const WALKING_DISTANCE_METER_PER_MINUTES = 60
