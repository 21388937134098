import { CURRENT_YEAR } from './date'

export const CURRENT_MIN_SALARY = { 2022: 9160, 2023: 9620, 2024: 9860, 2025: 10030 }[CURRENT_YEAR] ?? 9860

/**
 * 법률 상 취직 인허증이 필요한 최소 연령 계산
 * 해당 연도 생일이 지나지 않은 경우를 기준으로 만 14세 출생연도
 */
export const YOUTH_YEAR_MIN = new Date().getFullYear() - 15

/**
 * 법률 상 취직 인허증이 필요한 최대 연령 계산
 * 해당 연도 생일이 지나지 않은 경우를 기준으로 만 19세 출생연도
 */
export const YOUTH_YEAR_MAX = new Date().getFullYear() - 20

/**
 * 광고 관련 문의를 위한 고객센터 전화번호
 * @constant
 * @type {string}
 */
export const AD_ASK_TEL_NUMBER = '16449736'

// 근무 가능한 최대 생년
export const MAX_WORK_BIRTH_YEAR = 1920

/**
 * 365일 / 7일 = 52.142주
 * 52.142주 / 12개월 = 4.34516...주
 * 법적 기준에 따라 4.345주로 정함
 * https://daangn.slack.com/archives/C07TTEMNFA9/p1741249459822359
 */
export const WEEKS_PER_MONTH = 4.345
