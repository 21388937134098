import { ADMIN_USERS, BLUE_ADMIN_USERS, DESIGN_ADMIN_USERS, RED_ADMIN_USERS } from '@src/config'
import { SERVICE_KEY } from '@src/constants/api'

type ReferrerInfo = {
  referrer?: string
  referrer_context?: string
}

const initialValues: {
  isAdmin: boolean
  isDesignAdmin: boolean
  isRedAdmin: boolean
  isBlueAdmin: boolean
  accessToken: string
  bizAccessToken: string
  userAgent: string
  referrerInfo: ReferrerInfo
} = {
  isAdmin: false,
  isDesignAdmin: false,
  isRedAdmin: false,
  isBlueAdmin: false,
  accessToken: '',
  bizAccessToken: '',
  userAgent: '',
  referrerInfo: {},
}

class Storage {
  private cacheStorage = { ...initialValues }

  clearAll() {
    this.cacheStorage = { ...initialValues }
  }

  setToken(accessToken: string) {
    this.cacheStorage.accessToken = accessToken
  }

  getToken(): string {
    return this.cacheStorage.accessToken
  }

  removeToken() {
    this.cacheStorage.accessToken = initialValues.accessToken
  }

  setUserAgent(userAgent: string) {
    this.cacheStorage.userAgent = userAgent
  }

  getUserAgent(): string {
    return this.cacheStorage.userAgent
  }

  removeUserAgent() {
    this.cacheStorage.userAgent = initialValues.userAgent
  }

  setBizToken(bizAccessToken: string) {
    this.cacheStorage.bizAccessToken = bizAccessToken
  }

  getBizToken(): string {
    return this.cacheStorage.bizAccessToken
  }

  removeBizToken() {
    this.cacheStorage.bizAccessToken = initialValues.bizAccessToken
  }

  setReferrerInfo(referrerInfo: ReferrerInfo) {
    this.cacheStorage.referrerInfo = referrerInfo
  }

  getReferrerInfo() {
    return this.cacheStorage.referrerInfo
  }

  setIsAdmin(userId?: number) {
    if (!userId) return
    this.cacheStorage.isAdmin = ADMIN_USERS.includes(userId)
    this.cacheStorage.isDesignAdmin = DESIGN_ADMIN_USERS.includes(userId)
    this.cacheStorage.isRedAdmin = RED_ADMIN_USERS.includes(userId)
    this.cacheStorage.isBlueAdmin = BLUE_ADMIN_USERS.includes(userId)
  }

  getIsAdmin() {
    return this.cacheStorage.isAdmin
  }

  getIsBlueAdmin() {
    return this.cacheStorage.isBlueAdmin
  }

  getIsRedAdmin() {
    return this.cacheStorage.isRedAdmin
  }

  getIsDesignAdmin() {
    return this.cacheStorage.isDesignAdmin
  }

  setItemAtLocal(key: string, value: unknown) {
    window.localStorage.setItem(`${SERVICE_KEY}#${key}`, JSON.stringify(value))
  }

  getItemFromLocal<T = string>(key: string): T | null {
    const value = window.localStorage.getItem(`${SERVICE_KEY}#${key}`)

    const previousBooleanKeyWith1 = (key: string) => key.endsWith('CLICKED') || key.endsWith('SHOWN')
    if (previousBooleanKeyWith1(key) && ['1', '0'].includes(value ?? '')) {
      this.setItemAtLocal(key, value === '1')
      return (value === '1') as unknown as T
    }

    return typeof value === 'string' ? JSON.parse(value) : null
  }

  removeItemAtLocal(key: string) {
    window.localStorage.removeItem(`${SERVICE_KEY}#${key}`)
  }

  clearLocal() {
    window.localStorage.clear()
  }

  // check localStoaage is empty
  checkLocalEmpty() {
    return window.localStorage.length === 0
  }
}

export default new Storage()
