import { GraphQLResponse } from 'relay-runtime'

import { Middleware, RelayRequest } from '@src/types/relay'

interface Props {
  prefix?: string
  logger?: (...args: any[]) => void
}

// https://github.com/relay-tools/react-relay-network-modern/blob/master/src/middlewares/error.js

type GraphQLResponseErrors = Array<{
  message: string
  locations?: Array<{
    column: number
    line: number
  }>
  stack?: Array<string>
}>

export const errorMiddleware = (opts?: Props): Middleware => {
  const prefix = opts?.prefix || '[RELAY-NETWORK] GRAPHQL SERVER ERROR HOHO:\n\n'
  const logger = opts?.logger || console.error.bind(console)

  function displayErrors(errors: GraphQLResponseErrors, reqRes: { req: RelayRequest; res: GraphQLResponse }) {
    return errors.forEach((error) => {
      const { message, stack, ...rest } = error

      let msg = `${prefix}`
      const fmt = []

      if (stack && Array.isArray(stack)) {
        msg = `${msg}%c${stack.shift()}\n%c${stack.join('\n')}`
        fmt.push('font-weight: bold;', 'font-weight: normal;')
      } else {
        msg = `${msg}%c${message} %c`
        fmt.push('font-weight: bold;', 'font-weight: normal;')
      }

      if (rest && Object.keys(rest).length) {
        msg = `${msg}\n  %O`
        fmt.push(rest)
      }

      msg = `${msg}\n\n%cRequest Response data:\n  %c%O`
      fmt.push('font-weight: bold;', 'font-weight: normal;', reqRes)

      if (!stack) {
        msg = `${msg}\n\n%cNotice:%c${noticeAbsentStack()}`
        fmt.push('font-weight: bold;', 'font-weight: normal;')
      }

      logger(`${msg}\n\n`, ...fmt)
    })
  }

  return (next) => (req) => {
    return next(req).then((res) => {
      if (Array.isArray((res as any).errors)) {
        displayErrors((res as any).errors as GraphQLResponseErrors, { req, res })
      }

      return res
    })
  }
}

function noticeAbsentStack() {
  return `
    If you using 'express-graphql', you may get server stack-trace for error.
    Just tune 'formatError' to return 'stack' with stack-trace:

    import graphqlHTTP from 'express-graphql';

    const graphQLMiddleware = graphqlHTTP({
      schema: myGraphQLSchema,
      formatError: (error) => ({
        message: error.message,
        stack: process.env\u200b.NODE_ENV === 'development' ? error.stack.split('\\n') : null,
      })
    });

    app.use('/graphql', graphQLMiddleware);`
}
