export type TreatmentSymbol = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N'

export type Treament = {
  group: TreatmentSymbol
  percent: number
  description: string
}

// e.g BM_TASK_FEE: 'bm_task_fee'
export const EXPERIMENT_NAMES = {
  SUGGEST_UPDATING_JOB_POST_TITLE: 'suggest_updating_job_post_title',
  REGION_BOUNDARY: 'region_boundary',
  TOTAL_BUDGET_ADVERTISEMENT: 'total_budget_advertisement',
} as const

export type ExperimentName = (typeof EXPERIMENT_NAMES)[keyof typeof EXPERIMENT_NAMES]

export interface Experiment {
  name: ExperimentName
  description: string
  version: number
  exclude: number
  control: number
  treatments: Treament[]
  regions?: readonly { name1?: string; name2?: string; name3?: string }[]
  isDynamicAssiginment?: boolean
}

export const EXPERIMENTS: readonly Experiment[] = [
  {
    name: EXPERIMENT_NAMES.SUGGEST_UPDATING_JOB_POST_TITLE,
    description: '공고 제목 업데이트 권유 실험',
    version: 1,
    exclude: 0,
    control: 50,
    treatments: [{ group: 'A', percent: 50, description: '공고 제목 업데이트 권유' }],
  },
  {
    name: EXPERIMENT_NAMES.REGION_BOUNDARY,
    description: '바운더리 실험 UI 체크용 실험군',
    version: 1,
    exclude: 0,
    control: 90,
    treatments: [
      { group: 'A', percent: 10, description: '툴팁 없는 버전' },
      { group: 'B', percent: 90, description: '툴팁 있는 버전' },
    ],
  },
  {
    name: EXPERIMENT_NAMES.TOTAL_BUDGET_ADVERTISEMENT,
    description: '총예산 광고 실험',
    version: 1,
    exclude: 0,
    control: 50,
    treatments: [{ group: 'A', percent: 50, description: '총예산 광고' }],
    regions: [{ name1: '부산광역시' }, { name1: '울산광역시' }],
  },
] as const
