import { isFuture } from 'date-fns'

import { IS_ALPHA } from '@src/config'
import { CURRENT_YEAR } from '@src/constants/date'
import {
  REGEX_VALIDATE_MOBILE_NUMBER,
  REGEX_VALIDATE_PHONE,
  REGEX_ALPHA_VALIDATE_PHONE,
  REGEX_ALPHA_VALIDATE_MOBILE_NUMBER,
  REGEX_NON_NUMBER,
} from '@src/constants/regex'
import { BusinessCodeType } from '@src/types/certificate'
import { isNotEmpty } from '@src/utils/type'

const validatePhone = (regex: RegExp, phoneNumber?: string | null) => {
  if (!phoneNumber) {
    return false
  }

  const isMatched = regex.test(phoneNumber)
  if (!isMatched) {
    return false
  }

  const [input, primary, secondary, tertiary] = regex.exec(phoneNumber) || []
  if (input !== phoneNumber) {
    return false
  }
  switch (primary) {
    case '010':
      return secondary.length === 4 && tertiary.length === 4
    default:
      return secondary.length >= 3 && tertiary.length === 4
  }
}

export const validateName = (name?: string | null) => {
  if (!name) return false
  const validateLength = name.length >= 2
  const validateIncludeNumber = !/\d/.test(name)
  const validateInlcudeSpecialChar = !/[~`!#$@%^&*+=\-[\]\\';,/{}|\\":<>()?.]/.test(name)
  const validateIncludeEmoji = !/\p{Emoji_Presentation}/u.test(name)
  const validateMixCharacter = !/(?=.*[a-zA-Z])(?=.*[가-힣])/.test(name)
  const validateSingleCharacter = !/[ㄱ-ㅎ]/.test(name)
  const validateContinualSpace = !name.includes('  ')

  return [
    validateLength,
    validateIncludeNumber,
    validateInlcudeSpecialChar,
    validateIncludeEmoji,
    validateMixCharacter,
    validateSingleCharacter,
    validateContinualSpace,
  ].every((v) => v)
}

export const validatePhoneNumber = (phoneNumber?: string | null) => {
  return validatePhone(IS_ALPHA ? REGEX_ALPHA_VALIDATE_PHONE : REGEX_VALIDATE_PHONE, phoneNumber)
}

export const validateMobileNumber = (mobileNumber?: string | null) => {
  return validatePhone(IS_ALPHA ? REGEX_ALPHA_VALIDATE_MOBILE_NUMBER : REGEX_VALIDATE_MOBILE_NUMBER, mobileNumber)
}

export const validateDate = (date: string) => {
  const dateString = date.replaceAll(REGEX_NON_NUMBER, '')
  if (dateString.length !== 8) {
    return false
  }

  const [year, month, day] = [dateString.slice(0, 4), dateString.slice(4, 6), dateString.slice(6, 8)].map(Number)
  if (year < 1920 || year > CURRENT_YEAR) {
    return false
  }

  if (month < 1 || month > 12) {
    return false
  }

  const isLeapYear = year % 4 === 0
  const daysInMonth = [31, isLeapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  if (day < 1 || day > daysInMonth[month - 1]) {
    return false
  }

  return true
}

export const isStringArray = (value: any): value is string[] => {
  if (value instanceof Array) {
    return value.every((item) => {
      return typeof item === 'string'
    })
  }
  return false
}

export const isValidBusinessLicenseWithoutCompanyName = ({
  validatedAt,
  expireAt,
}: {
  validatedAt?: Nullable<Dateable>
  expireAt?: Nullable<Dateable>
}) => {
  return !!validatedAt && !!expireAt && isFuture(new Date(expireAt))
}

export const isValidBusinessLicense = ({
  validatedAt,
  expireAt,
  businessCodeType,
  companyName,
}: {
  validatedAt?: Nullable<Dateable>
  expireAt?: Nullable<Dateable>
  businessCodeType?: Nullable<BusinessCodeType>
  companyName?: Nullable<string>
}) => {
  const isValidBusinessLicense = isValidBusinessLicenseWithoutCompanyName({ validatedAt, expireAt })
  if (!isValidBusinessLicense) {
    return false
  }

  return businessCodeType === 'BUSINESS_REGISTRATION' ? isNotEmpty(companyName) : true
}
